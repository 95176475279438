<template>
  <b-container>
    <b-row>
      <b-col class="my-3">
        <h4 class="border-bottom">臨床研究にご参加の同意について</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="my-3">
        <p>
        東海大 臨床研究「特定保健指導積極的支援対象者に対するピアサポート型習慣化アプリによる
        行動変容の評価のためのランダム化比較試験(PEER-App Study)」（以下本臨床研究）の説明会にご参加いただきありがとうございます。
        あわせて、<a :href="docHostUrl + '/consent-210906.pdf'" target="_blank">同意説明書</a>および
        <b-link href="/consent/desc#privacy" target="_blank">個人情報の取扱いについて</b-link>をご確認ください。<br><br>
        説明した項目を理解し、同意いただける場合は、「同意します」にチェックをして、下記フォームを入力いただき、「送信する」ボタンを押してしてください。<br><br>
        もしも分かりにくい内容やご不明な点があった場合やさらに詳しい説明が必要でしたら担当までご遠慮なくおたずねください。<br><br>
        「確認して送信する」ボタンを押すことで、本臨床研究へのご協力に同意したことになります。<br>
        </p>
        <div class="border border-1">
          【説明を受け理解した項目】
          <ul style="list-style-type: none">
            <li>
            １．臨床研究とは
            </li>
            <li>
            ２．習慣化について
            </li>
            <li>
            ３．この研究の目的
            </li>
            <li>
            ４．この研究の方法
            </li>
            <li>
            ５．この研究に協力していただく参加者さんの人数及び臨床研究期間
            </li>
            <li>
            ６．あなたに守っていただきたいこと
            </li>
            <li>
            ７．この研究への協力により予想される利益と不利益
            </li>
            <li>
            ８．臨床研究への協力の自由と協力のとりやめについて
            </li>
            <li>
            ９．この研究に協力しない場合の他の治療方法について
            </li>
            <li>
            １０．臨床研究が中止される場合
            </li>
            <li>
            １１．習慣化に関する新しい情報の提供について
            </li>
            <li>
            １２．あなたの人権・プライバシーの保護について
            </li>
            <li>
            １３．臨床研究に関連して健康被害が発生した場合の治療及び補償について
            </li>
            <li>
            １４．研究組織の概要、費用の負担、研究資金などについて
            </li>
            <li>
            １５．この臨床研究を担当する医師および研究者の氏名、連絡先
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="my-3">
        <b-card>
        <b-checkbox v-model="checked">
          私は、臨床研究「特定保健指導積極的支援対象者に対するピアサポート型習慣化アプリによる行動変容の評価のためのランダム化比較試験」について
          同意説明文書に基づいて担当者より上記項目の説明を受け、その内容を十分理解し納得しました。<br>
          その結果、私の自由意思によりこの臨床研究に協力することに同意します。
        </b-checkbox>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-card>
          <div class="mb-1">
          <b-form-group
            label="お名前を入力してください"
            label-for="input-name"
            class="mb-0"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="名前"
              :state="stateName"
              required
              autocomplete="name"
              @blur="validateName"
            ></b-form-input>
            <b-form-invalid-feedback id="input-name-feedback">
              お名前を入力してください
            </b-form-invalid-feedback>
          </b-form-group>
          </div>
          <div class="mb-1">
          <b-form-group
            label="名前カナ"
            label-for="input-kana"
            class="mb-0"
          >
            <b-form-input
              id="input-kana"
              v-model="form.nameKana"
              placeholder="カナ"
              :state="stateNameKana"
              required
              autocomplete="cc-name"
              @blur="validateNameKana"
            ></b-form-input>
            <b-form-invalid-feedback id="input-kana-feedback">
              お名前カナを入力してください
            </b-form-invalid-feedback>
          </b-form-group>
          </div>
          <div class="mb-1">
          <b-form-group
            label="メールアドレス"
            label-for="input-formatter-email"
            class="mb-0"
          >
            <b-form-input
              id="input-email"
              type="email"
              v-model="form.email"
              placeholder="メールアドレス"
              :state="stateEmail"
              required
              autocomplete="email"
              @blur="validateEmail"
            ></b-form-input>
            <b-form-invalid-feedback id="input-email-feedback">
              メールアドレスを正しく入力してください
            </b-form-invalid-feedback>
          </b-form-group>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="my-3">
        <b-btn block size="lg" variant="success" :disabled="!checked && !loading" @click="onPost">
          <strong>確認して送信する</strong>
        </b-btn>
      </b-col>
    </b-row>
	</b-container>
</template>

<script>
import { Logger } from 'aws-amplify'

const logger = new Logger('Form')

/* eslint no-irregular-whitespace: ["error", {"skipRegExps": true}] */
const NAMEKANA_REGEX = /^[ァ-ヶー 　]+$/
const EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

export default {
  name: 'Form',
  data () {
    return {
      docHostUrl: process.env.VUE_APP_DOC_HOST_URL,
      loading: false,
      message: null,
      error: null,
      checked: false,
      form: {
        name: null,
        nameKana: null,
        email: null
      },
      stateName: null,
      stateNameKana: null,
      stateEmail: null
    }
  },
  methods: {
    validateName () {
      this.stateName = this.form.name && this.form.name.length > 1 ? true : false
      return this.stateName
    },
    validateNameKana () {
      this.stateNameKana = this.form.nameKana && NAMEKANA_REGEX.test(this.form.nameKana) ? true : false
      return this.stateNameKana
    },
    validateEmail () {
      this.stateEmail = this.form.email && EMAIL_REGEX.test(this.form.email) ? true : false
      return this.stateEmail
    },
    validate () {
      logger.debug([ this.validateName(), this.validateNameKana(), this.validateEmail() ])
      return [ this.validateName(), this.validateNameKana(), this.validateEmail() ].every(a => !!a)
    },
    async onPost () {
      if (!this.validate()) return
      this.loading = true
      try {
        const res = await this.$store.dispatch('postConsent',{
          name: this.form.name, nameKana: this.form.nameKana, email: this.form.email
        })
        logger.debug(res)
        // this.loading = false
        this.$router.push('/consent/post')
      } catch (error) {
        logger.debug(error)
        this.loading = false
        this.error = error
      }
    }
  }
}
</script>

